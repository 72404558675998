import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"
import theme from "~theme/theme"
import useBreakpoint from '~utils/use-breakpoint'

import { CloseIcon } from "~theme/common"

const MenuPageSidebar = () => {
  const { sm } = useBreakpoint()

  return (
    <MenuPageSidebarWrapper>
      <p className="medium">
        <Link to="/editors">Editors</Link><br />
        <Link to="/contributors">Contributors</Link><br />
        <Link to="/team">Team</Link><br />
        <Link to="/submit">Share your ideas</Link><br />
        <br />
        {sm &&
          <Link to="/">
          <span style={{display: 'inline-block', transform: 'translateY(-0.1em)', marginRight: '0.3em'}}>×</span>
          Close Menu</Link>
        }
        {!sm &&
          <SidebarCloseIcon to="/">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 103.71 103.71">
            <polygon points="103.71,11.23 92.48,0 51.85,40.63 11.23,0 0,11.23 40.63,51.85 0,92.48 11.23,103.71 51.85,63.08 92.48,103.71
            103.71,92.48 63.08,51.85 "/>
            </svg>
          </SidebarCloseIcon>
        }
      </p>

      <CreditLink>
        <span className="small">Design by <a href="https://oakpark.co/" target="_blank">Oak Park</a></span>
      </CreditLink>
    </MenuPageSidebarWrapper>
  )
}

const MenuPageSidebarWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    margin-bottom: 0;
  }
  a {
    border: 0;
    text-decoration: none;
  }
`

const CreditLink = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    position: fixed;
  }
  a {
    display: inline-block;
  }
`

export const SidebarCloseIcon = styled(CloseIcon).attrs({
  as: Link
})`
  position: absolute;
  padding: 0;
  top: 1.5rem;
  right: 1rem;
  width: 1rem;
`

export default MenuPageSidebar
